<template>
    <div class="honor-swiper">
        <div >
            <swiper
                :loop='true'
                :disableOnInteraction="false"
                :slidesPerView="4"
                :spaceBetween="30"
                :freeMode="true"
                :speed='5000'
                :autoplay="aotuplay"
                ref="mySwiper"
            >
                <swiper-slide class="flex align_center justify_center" v-for="item in honorArr" :key="item.imgUrl">
                    <div class="img"><img draggable="false" :src="item.imgUrl" alt=""></div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
import SwiperCore, { Autoplay } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import { ref } from '@vue/reactivity';
import { getHonor } from '@/api/about'

SwiperCore.use([ Autoplay ])

export default {
    name: 'Honor',
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
        const mySwiper = ref(null)
        const aotuplay = ref({delay: 0,stopOnLastSlide: false,disableOnInteraction: true})
        const honorArr = ref([])

        const Enter = () => {
            aotuplay.value = false
        }

        const Leave = () => {
            aotuplay.value = {delay: 0,stopOnLastSlide: false,disableOnInteraction: true}
        }

        const getHonorList = async() => {
            let data = await getHonor()
            if(data.status === 200) {
                honorArr.value = data.data.data
            }
        }
        getHonorList()

        return {
            aotuplay,
            mySwiper,
            honorArr,
            Enter,
            Leave
        }
    },
}
</script>
<style lang="scss">
    .honor-swiper {
        margin-top: 30px;
        .img,img {
            width: 100%;
            height: 100%;
        }
        .flex {
            width: 250px;
            height: 172px;
        }
        .swiper-container .swiper-wrapper{
            -webkit-transition-timing-function: linear; /*之前是ease-out*/
            -moz-transition-timing-function: linear;
            -ms-transition-timing-function: linear;
            -o-transition-timing-function: linear;
            transition-timing-function: linear;
        }
    }
</style>